.cakeContainer{
	min-height: 100vh;
	padding: 5rem calc((100vw - 1300px) / 2);
	background: #423030;
	color: #fff;
}

.cakeWrapper{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
}

.cakeCard{
	margin: 0 2rem;
	line-height: 2;
	width: 300px;
}
.cakeImg{
	height: 200px;
	box-shadow: 5px 3px #7f5f19;
  width: auto;
}

.cakeHeading{
	font-size: clamp(1rem, 2vw, 2rem);
	text-align: center;
	margin-bottom: 3rem;
}

.protTitle{
	font-weight: 400;
	font-size: 2rem;
}

.protDescription{
	font-weight: 200;
	font-size: 1rem;
}

.cakeInfo{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem;
	text-align: left;
}

.cakeDesc{
	margin-bottom: 1rem;
}

.cakePrice{
	margin-bottom: 1rem;
	font-size: 1.5rem;
	margin-top: auto;

}

.cakeBtn{
	font-size: 1rem;
	padding: 1rem 4rem;
	border: none;
	background: #F76D28;
	color: #fff;
	transition: 0.3 ease-out;
}
.cakeBtn:hover {
	background: #5203C3;

  transition: 0.2s ease-out;
  cursor: pointer;
}