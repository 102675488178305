.baristaImg{
	height: 300px;
	border: solid 2px black;
  width: 300px;
  object-fit: cover;
}

.baristaContainer{
	min-height: 100vh;
	padding: 5rem calc((100vw - 1300px) / 2);
	background: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)), url('../images/kitchen.jpg');
	color: rgb(255, 255, 255);
}