.navbar{
	background: 'transparent';
	height: 80px;
	display: flex;
	width: 100vw;
	justify-content: center;
	font-weight: 700;
	position: fixed;
	transition: transform 1000ms;
}

.navbar.move{
  transform: translateY(-200px);
}

.navlink{
	color: #fff;
	font-size: 2rem;
	display: flex;
	align-items: center;
	text-decoration: none;
	cursor: pointer;
}


@media screen and (max-width: 530px) {
  .navlink {
    position: absolute;
    top: 10px;
    left: 25px;
  }

	.desktop{
		display:none;
		height: 15%;
	}
}

.navicon{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	color: #fff;
	transform: translate(100%, 50%);
	font-weight: bold;
	font-size: 2rem;
};
